import React from "react";
import logo from "../Img/noxsentinel.jpg";

const Benefits = () => (
  <section className="benefits">
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>Benefits</h2>
        <ul>
          <li>
            <strong>Peace of Mind:</strong> Proactive protection to stay ahead
            of potential risks.
          </li>
          <li>
            <strong>Enhanced Productivity:</strong> Focus on business growth,
            not security concerns.
          </li>
          <li>
            <strong>Easy to Integrate:</strong> Works seamlessly with existing
            systems.
          </li>
          <li>
            <strong>Scalability:</strong> Ideal for businesses of all sizes,
            from startups to enterprises.
          </li>
        </ul>
      </div>

      <img src={logo} alt="Nox Sentinel Logo" className="logo" />
    </div>
  </section>
);

export default Benefits;
