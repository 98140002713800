import React from "react";
import logo from "../Img/ns2.jpg";

const Features = () => (
  <section className="features">
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <img src={logo} alt="Nox Sentinel Logo" className="logo" />

      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>Features</h2>
        <ul>
          <li>
            <strong>Real-Time Vulnerability Scanning:</strong> Constant
            monitoring for potential threats.
          </li>
          <li>
            <strong>Detailed Reporting:</strong> Comprehensive and actionable
            vulnerability reports.
          </li>
          <li>
            <strong>Customizable Alerting:</strong> Configure alerts for
            critical vulnerabilities.
          </li>
          <li>
            <strong>Automatic Updates:</strong> Keeps up-to-date with the latest
            threat intelligence.
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Features;
