import React from "react";

const Header = () => (
  <header className="header">
    <h1>Nox Sentinel</h1>
    <p>Security vigilance by Nox Ignis Software</p>
  </header>
);

export default Header;
